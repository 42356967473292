import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import servicedata from '../constants/service-data';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    textAlign: 'center',
    height: '100%',
    width: '100%'
  }
}));

export default ({ data, md, xs }) => {
  const Data = (data) ? data : servicedata
  const MD = (md) ? md : 3
  const XS = (xs) ? xs : 12
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container alignItems="stretch" spacing={2}>
        {Data.map((service, index) => {
          return (
            <Grid key={index} item xs={XS} md={MD} style={{ display: 'flex' }}>
              <Card key={service.id} className={classes.serviceCard}>
                <CardContent>
                  <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
                    {(service.image_direction == 'C') &&
                      <Avatar alt="people" src={service.image} style={{ height: (service.image_height) ? service.image_height : 128, width: (service.image_width) ? service.image_width : 128 }} />
                    }
                  </div>
                  <Typography variant="h6" gutterBottom className={classes.serviceTitle}>{service.title}</Typography>
                  <Typography variant="subtitle1" style={{ fontWeight: 'normal' }} gutterBottom>{service.text}</Typography>
                  {(service.buttonLink !== '' && service.buttonLink !== undefined) &&
                    <Link to={service.buttonLink}>
                      <Button variant="contained" size="large" color={(service.buttonColor) ? service.buttonColor : "primary"}>{service.button}</Button>
                    </Link>
                  }
                </CardContent>
              </Card>
            </Grid>
          )
        }
        )}
      </Grid>
    </React.Fragment>
  )
}

