const palette = {
  type: 'dark',
  primary: {
    main: '#AEC702',
  },
  secondary: {
    main: '#CF780F',
  },
  background: {
    default: '#000',
    footer: '#1b1642',
  },
}

export default palette
