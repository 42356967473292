export const converttourl = (data) => {
    if (!data) {
        return "";
    }
    return data.replace(/\s+/g, "-").toLowerCase();
}

export const converttotitle = (data) => {
    if (!data) {
        return "";
    }
    const arr = data.toLowerCase().split('-');
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
}

export const isBrowser = () => typeof window !== "undefined"


export const getpageformurl = (str) => {
    if (!str) str = '';
    str = str.split('\\').pop().split('/').pop();
    return (str != "") ? str : "/";
}

export const isblank = (value) => {
    if (value === undefined || value === null || value === "") {
        return true
    }
    return false;
}


export const validateEmail = (email) => {
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(mailformat)) {
        return true;
    }
    return false;
}

