import { Box, Divider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Email, Phone } from '@material-ui/icons'
import RoomIcon from '@material-ui/icons/Room'
import { CustomButton3, Section } from '@sites/shared-ui'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import FooterLinks from '../constants/footer-links'

const useStyles = makeStyles(theme => ({
  copyright: {
    display: 'flex !important',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'block !important',
      textAlign: 'center',
    },
  },
  backgroundContainer: {
    backgroundImage: `url("/footer-right.jpeg")`,
    backgroundPosition: '100% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    padding: 80,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 32
    },
  },
  home__bg: {
    objectFit: 'cover',
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <footer>
      <React.Fragment>
        <Section fullWidth disablePadding>
          <Grid container alignItems="stretch">
            <Grid item xs={12} md={6}>
              <StaticImage
                objectFit="cover"
                objectPosition="50% 50%"
                src="../images/footer.jpeg"
                alt="Lotus Group"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={classes.backgroundContainer}>
                <Typography variant="h6" gutterBottom>
                  CONTACT
                </Typography>
                <Typography
                  style={{ marginBottom: 16, fontWeight: 'bold' }}
                  variant="h2"
                >
                  Get In Touch
                </Typography>

                <List>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <RoomIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle1">LOTUS HEIGHTS, NEAR.GEBANSHA, ISANPUR, AHMEDABAD 382443</Typography>} />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle1">+91 9265765215</Typography>} />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    <ListItemText primary={<Typography variant="subtitle1">lotusheights1@gmail.com</Typography>} />
                  </ListItem>
                </List>

                <CustomButton3
                  style={{ marginTop: 16 }}
                  href="/contact"
                  rel="noreferrer"
                  target="_blank"
                >
                  Get Directions
                </CustomButton3>

                {/* <div style={{ marginTop: 32 }}>
                  <FooterLinks type="footer_menu_1" />
                </div> */}
              </Box>
            </Grid>
          </Grid>
        </Section>

        <Section className={classes.copyright}>
          <Typography>&reg; 2021 Lotus Group. All Rights Reserved</Typography>
          <Typography>
            Developed by{' '}
            <a style={{ color: '#fff' }} href="https://arihantsolutions.com" target="_blank">
              Arihant Solutions
            </a>
          </Typography>
        </Section>
      </React.Fragment>
    </footer>
  )
}

export default Footer
