import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import socialLinks from '../constants/socialLinks-data';

const useStyles = makeStyles((theme) => ({
  sociallink: {
    padding: 0
  }
}));

export default ({ data, dark }) => {
  const Data = (data) ? data : socialLinks;
  return (
    Data.map((link, index) => {
      return (
        <IconButton color={(dark) ? "secondary" : "primary"} key={`s-${index}`} href={link.url} style={{ padding: 5 }} aria-label={link.title}>
          {link.icon}
        </IconButton>
      )
    })
  )
}
