import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import './index.css'

const useStyles = makeStyles((theme) => ({
}));

export default ({ children }) => {
  const classes = useStyles();
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <React.Fragment>
      <Slider {...settings}>
        {children}
      </Slider>
    </React.Fragment>
  )
}

