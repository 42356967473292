import React, { useRef, useLayoutEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import { ThemeProvider } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import './index.css'
import {
  getpageformurl,
  converttourl,
} from '@sites/shared-ui/util/commonfunctions'
import theme from './theme';
import {
  handleMouseDown,
  handleMouseEnter,
  handleMouseMove,
  handleMouseOut,
  handleMouseUp,
  handleMouseHoverEnter,
} from '@sites/shared-ui/util/mouse'

const Layout = props => {
  const themev1 = useTheme()
  const is_mobile = useMediaQuery(themev1.breakpoints.down('sm'))
  const catMenu = []
  const { children, header, footer } = props
  const bigMouseCircle = useRef()
  const smallMouseCircle = useRef()

  useLayoutEffect(() => {
    setTimeout(function() {
      // Bind the event listener
      document.addEventListener(
        'mousemove',
        handleMouseMove(bigMouseCircle, smallMouseCircle)
      )
      document.addEventListener(
        'mousedown',
        handleMouseDown(bigMouseCircle, smallMouseCircle)
      )
      document.addEventListener(
        'mouseup',
        handleMouseUp(bigMouseCircle, smallMouseCircle)
      )
      document.addEventListener(
        'mouseout',
        handleMouseOut(bigMouseCircle, smallMouseCircle)
      )
      document.addEventListener(
        'mouseenter',
        handleMouseEnter(bigMouseCircle, smallMouseCircle)
      )
      document.addEventListener(
        'mouseover',
        handleMouseHoverEnter(bigMouseCircle, smallMouseCircle)
      )

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener(
          'mousemove',
          handleMouseMove(bigMouseCircle, smallMouseCircle)
        )
        document.removeEventListener(
          'mousedown',
          handleMouseDown(bigMouseCircle, smallMouseCircle)
        )
        document.removeEventListener(
          'mouseup',
          handleMouseUp(bigMouseCircle, smallMouseCircle)
        )
        document.removeEventListener(
          'mouseout',
          handleMouseOut(bigMouseCircle, smallMouseCircle)
        )
        document.removeEventListener(
          'mouseenter',
          handleMouseEnter(bigMouseCircle, smallMouseCircle)
        )
        document.removeEventListener(
          'mouseover',
          handleMouseHoverEnter(bigMouseCircle, smallMouseCircle)
        )
      }
    }, 100)
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <div className="cursor" style={{ opacity: is_mobile ? 0 : 1 }}>
        <div ref={bigMouseCircle} className="cursor__ball cursor__ball--big" />
        <div
          ref={smallMouseCircle}
          className="cursor__ball cursor__ball--small"
        />
      </div>
      {header !== 'false' && <Header menu={catMenu} />}
      <main>{children}</main>
      {footer !== 'false' && <Footer menu={catMenu} />}
    </ThemeProvider>
  )
}

export default Layout
