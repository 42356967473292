import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import whySectiondata from '../constants/whySection-data';
import Section from './Section';

import { Icon } from '@sites/shared-ui';

const useStyles = makeStyles((theme) => ({
  nopad: {
    padding: 0,
    alignItems: 'baseline'
  },
  root: {
    paddingTop: 48,
    paddingBottom: 48
  }
}));



export default ({ data }) => {
  const classes = useStyles();
  const Data = (data) ? data : whySectiondata;
  return (
    <div style={{ backgroundColor: '#cfe8fc' }}>
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="h5" gutterBottom>Why GetMyStore?</Typography>
            <List disablePadding>
              {Data.map(link => {
                return (
                  <ListItem className={classes.nopad} key={`why${link.id}`}>
                    <div style={{ marginRight: 8 }} ><Icon name="arrowright" size={16} /></div>
                    <Typography variant="body1" style={{ fontSize: 20 }} gutterBottom>{link.text}</Typography>
                  </ListItem>
                )
              }
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={5}>
            <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src="https://cdn.arihantsolutions.com/gms/product_catalogue_1.png" />
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}