import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../Section';

const useStyles = makeStyles({
  bgImg: {
    backgroundImage: props => `url(${props.backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'scroll'
  },
  bgAttachment: {
    backgroundAttachment: 'fixed'
  },
  bgShadow: {
    boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)'
  },
  bgColor: {
    backgroundColor: props => props.backgroundColor,
  },
  bgPosition: {
    backgroundPosition: props => props.backgroundPosition
  }
});

/**
 * Component to display the hero with background
 */
const HeroBackground = props => {
  const {
    children,
    backgroundPosition,
    backgroundImg,
    backgroundColor,
    className,
    boxShadow,
    parallax,
    ...rest
  } = props;

  const classes = useStyles(props);

  return (
    <div
      className={clsx(
        'background-wrapper',
        backgroundImg ? classes.bgImg : {},
        backgroundPosition ? classes.bgPosition : {},
        backgroundColor ? classes.bgColor : {},
        boxShadow ? classes.bgShadow : {},
        parallax ? classes.bgAttachment : {},
        className,
      )}>
      <Section {...rest}>
        {children}
      </Section>
    </div>
  );
};

HeroBackground.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * backgroundPosition
   */
  backgroundPosition: PropTypes.string,
  /**
   * backgroundImg
   */
  backgroundImg: PropTypes.string,
  /**
   * backgroundColor
   */
  backgroundColor: PropTypes.string,
  /**
  * Section
  */
  boxShadow: PropTypes.bool,
  parallax: PropTypes.bool

};

export default HeroBackground;