import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../Section';
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles({
    bgColor: {
        backgroundColor: props => props.backgroundColor,
    },
    styledClass: {
        zIndex: 2,
        display: 'flex',
        alignItems: 'center'
    },
    unstyledClass: {

    }
});

/**
 * Component to display the hero with background
 */
const HeroGrid = props => {
    const {
        backgroundColor,
        leftmd,
        leftContent,
        rightContent,
        rightmd,
        styled,
        spacing,
        ...rest
    } = props;

    const classes = useStyles(props);
    const leftMd = (leftmd) ? leftmd : 6;
    const rightMd = (rightmd) ? rightmd : 6;
    const gridSpacing = (spacing) ? spacing : 2;
    return (
        <div
        className={clsx(
            'background-wrapper',
            backgroundColor ? classes.bgColor : {}
        )}>
        <Section {...rest}>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={leftMd}>
                    {leftContent}
                </Grid>
                <Grid item xs={12} md={rightMd} className={styled ? classes.styledClass : classes.unstyledClass}>
                    {rightContent}
                </Grid>
            </Grid>
        </Section>
        </div>
    );
};

HeroGrid.propTypes = {
    backgroundColor: PropTypes.string,
    leftmd: PropTypes.number,
    rightmd: PropTypes.number,
    spacing: PropTypes.number,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    styled: PropTypes.bool
};

export default HeroGrid;