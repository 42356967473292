import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Section } from '@sites/shared-ui';
import { Link } from "gatsby";
import React from "react";
import servicedata from '../constants/service-data';

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    marginBottom: 32,
    padding: theme.spacing(2),
  }
}));

export default ({ data, altRowColor, addToRefs }) => {
  const Data = (data) ? data : servicedata;
  const classes = useStyles();
  return (
    <React.Fragment>
      {Data.map((service, index) => {
        return (
          <Section fullWidth disablePadding style={{ backgroundColor: (index % 2) ? altRowColor : 'transparent'}}>
            <Section>
              <Grid key={index} container spacing={2}>
              {(service.image_direction == 'L') &&
                <Grid item xs={12} md={4}>
                  <img ref={addToRefs} className="gs_zoom" style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                </Grid>
              }
              <Grid item xs={12} md={8}>
                <Typography ref={addToRefs} className="gs_reveal" variant="h4" gutterBottom className={classes.serviceTitle}>{service.title}</Typography>
                <Typography ref={addToRefs} className="gs_reveal" variant="h6" style={{ fontWeight: 'normal', marginBottom: 16, whiteSpace: 'pre-wrap' }} gutterBottom>{service.text}</Typography>
                <Link to={service.buttonLink}>
                  <Button variant="contained" disableElevation size="large">{service.button}</Button>
                </Link>
              </Grid>
              {(service.image_direction == 'R') &&
                <Grid item xs={12} md={4}>
                  <img ref={addToRefs} className="gs_zoom" style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                </Grid>
              }
            </Grid>
            </Section>
          </Section>
        )
      }
      )}
    </React.Fragment>
  )
}

