const prod = {
    BASE_URL: 'https://api.finalbooks.in/account',
    LIMIT: 15,
    LIMITALL: 'All',
    SALTKEY: 'india@k1e2y3',
    ENCDECKEY: "0123456789abcdef0123456789abcdef",
    ENCDECIV: "abcdef9876543210abcdef9876543210",
};

const dev = {
    BASE_URL: 'http://api.finalbooks.local/account',
    LIMIT: 15,
    LIMITALL: 'All',
    SALTKEY: 'india@k1e2y3',
    ENCDECKEY: "0123456789abcdef0123456789abcdef",
    ENCDECIV: "abcdef9876543210abcdef9876543210",
};

const staging = {
    BASE_URL: 'https://api-staging.finalbooks.in/account',
    LIMIT: 15,
    LIMITALL: 'All',
    SALTKEY: 'india@k1e2y3',
    ENCDECKEY: "0123456789abcdef0123456789abcdef",
    ENCDECIV: "abcdef9876543210abcdef9876543210",
};
// export const config = process.env.REACT_APP_NODE_ENV === 'production' ? prod : process.env.REACT_APP_NODE_ENV === 'staging' ? staging : dev;
export const config = dev;