import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image/withIEPolyfill"
import Slider from "react-slick";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import 'react-image-lightbox/style.css'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({}));

const Partners = ({
  images = null,
  imgClass = ''
}) => {
  let thumbsArray, fullArray, thumbAltArray

  // New style with all images in one prop
  thumbsArray = images
    .filter((thumb) => thumb !== undefined)
    .map(({ thumb }) => thumb)
  thumbAltArray = images.map(({ thumbAlt }) => thumbAlt)
  fullArray = images
    .filter((image) => image.full !== undefined)
    .map(({ full }) => full.src)

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles();
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };


  return (
    <React.Fragment>
      <Slider {...settings}>
      {thumbsArray.map((thumbnail, thumbIndex) => {
          return (
          <div 
            key={thumbIndex}>
            <Img
                fluid={thumbnail}
                className={imgClass}
                alt={
                thumbAltArray
                    ? thumbAltArray[thumbIndex]
                    ? thumbAltArray[thumbIndex]
                    : ''
                    : ''
                }
            />
          </div>
          )
        }
        )}
      </Slider>
    </React.Fragment>
  )
}

export default Partners

Partners.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      full: PropTypes.object,
      thumb: PropTypes.object,
      thumbAlt: PropTypes.string,
      title: PropTypes.node,
      caption: PropTypes.node,
    })
  ),
  imgClass: PropTypes.string
}
