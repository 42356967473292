import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import 'react-image-lightbox/style.css'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

const useStyles = makeStyles(theme => ({
  squareimg: {
    height: 350,
    width: 350
  }
}));

const PhotoAlbum = ({
  images = null,
  title = null,
  imgClass = null,
  lightboxOptions = {},
  width,
  rowHeight,
  gap
}) => {
  let thumbsArray, fullArray, thumbAltArray

  // New style with all images in one prop
  thumbsArray = images
    .map((full) => full.url)
  thumbAltArray = images.map((thumbAlt) => thumbAlt)
  fullArray = images
    .map((full) => full.url)

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const prevIndex = index - (1 % fullArray.length)
  const nextIndex = (index + fullArray.length + 1) % fullArray.length
  const classes = useStyles();
  const customStyles = {
    overlay: { zIndex: 100001 }
  }

  const getListCols = () => {
    if (isWidthUp('lg', width)) {
      return 4;
    }

    if (isWidthUp('md', width)) {
      return 3;
    }

    return 2;
  }

  const getRowHeight = () => {
    if (isWidthUp('lg', width)) {
      return 250;
    }

    if (isWidthUp('md', width)) {
      return 200;
    }

    return 150;
  }

  return (
    <React.Fragment>
      <ImageList rowHeight={(rowHeight) ? rowHeight : 150} gap={(gap) ? gap : 1} cols={getListCols()}>
        {thumbsArray.map((thumbnail, thumbIndex) => {
          return (
            <ImageListItem
              key={thumbIndex}
              onClick={() => {
                setIsOpen(true)
                setIndex(thumbIndex)
              }}>
              <img
                src={thumbnail}
                style={imgClass}
              />
            </ImageListItem>
          )
        }
        )}
      </ImageList>

      {isOpen && (
        <Lightbox
          mainSrc={fullArray[index]}
          nextSrc={fullArray[nextIndex]}
          prevSrc={fullArray[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageTitle={title}
          reactModalStyle={customStyles}
          //imageCaption={images[index].caption}
          {...lightboxOptions}
        />
      )}
    </React.Fragment>
  )
}

export default withWidth()(PhotoAlbum);

PhotoAlbum.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      full: PropTypes.object,
      thumb: PropTypes.object,
      thumbAlt: PropTypes.string
    })
  ),
  colWidth: PropTypes.number,
  mdColWidth: PropTypes.number,
  gutter: PropTypes.string,
  imgClass: PropTypes.string,
  lightboxOptions: PropTypes.object,
}
