import React from "react"
export default {
  features:{
    id: 1,
    category_name: `Features`,
    items: [
    {
      id: 1,
      icon: "code",
      icon_direction: "L",
      title: "Smart Dashboard",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 2,
      icon: "sketch",
      icon_direction: "L",
      title: "Membership Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 3,
      icon: "android",
      icon_direction: "L",
      title: "Class and appointment scheduling",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 4,
      icon: "addressbook",
      icon_direction: "L",
      title: "Smart Lead Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 5,
      icon: "code",
      icon_direction: "L",
      title: "Task Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 6,
      icon: "code",
      icon_direction: "L",
      title: "Workout Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 7,
      icon: "code",
      icon_direction: "L",
      title: "Diet plan Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 8,
      icon: "code",
      icon_direction: "L",
      title: "Discount Codes and Promotion Management",
      text: `Manage every aspect of your fitness`,
    },
    {
      id: 9,
      icon: "code",
      icon_direction: "L",
      title: "Mobile Apps",
      text: `Manage every aspect of your fitness`,
    }
    ]
  },
  reasons:{
    id: 2,
    category_name: `More reasons to choose StudioBooks`,
    items: [
      {
        id: 1,
        icon: "code",
        icon_direction: "C",
        title: "Bot Automation",
        text: `Manage every aspect of your fitness`,
      },
      {
        id: 2,
        icon: "code",
        icon_direction: "C",
        title: "Biometrics Integration",
        text: `Manage every aspect of your fitness`,
      },
      {
        id: 3,
        icon: "code",
        icon_direction: "C",
        title: "Built on an enterprise-grade foundation",
        text: `The StudioBooks platform is hosted on AWS and follows robust standards and security frameworks to deliver a secure solution for your business.`,
      }
    ]
  }
}
