import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import FeaturesItems from "../constants/features"
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Icon } from '@sites/shared-ui'

const useStyles = makeStyles((theme) => ({
  testiCard: {
    backgroundColor: 'transparent',
    outline: 'none',
    color: '#fff'
  },
  hero: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'scroll',
    padding: 20
  }
}));

function FeatureSection({ featuresdata, column, type }) {
  const classes = useStyles();
  const featuresData = (featuresdata) ? featuresdata : FeaturesItems[`${type}`]

  return (
    <React.Fragment>
        <Typography variant="h4" style={{ textAlign: 'center' }} gutterBottom>{featuresData.category_name}</Typography>
        <Grid
          direction="row"
          justify="center"
          alignItems="stretch"
          container
          spacing={2}>
          {featuresData.items.map(data => {
            return (
              <Grid key={data.id} item xs={12} md={(12 / column)}>
                <Card style={{ height: '100%' }} elevation={0}>
                  <CardContent>
                    {(data.icon_direction == 'L') &&
                      <Grid
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        container
                        spacing={2}>
                        <Grid key={`icon-${data.id}`} item xs={12} md={2}>
                          <Icon name={data.icon} size={44} />
                        </Grid>
                        <Grid key={`text-${data.id}`} item xs={12} md={10}>
                          <Typography variant="h6" gutterBottom component="h2">{data.title}</Typography>
                          <Typography variant="body1" component="p">{data.text}</Typography>
                        </Grid>
                      </Grid>
                    }
                    {(data.icon_direction == 'R') &&
                      <Grid
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        container
                        spacing={2}>
                        <Grid key={`text-${data.id}`} item xs={12} md={10}>
                          <Typography variant="h6" gutterBottom component="h2">{data.title}</Typography>
                          <Typography variant="body1" component="p">{data.text}</Typography>
                        </Grid>
                        <Grid key={`icon-${data.id}`} item xs={12} md={2}>
                          <Icon name={data.icon} size={44} />
                        </Grid>
                      </Grid>
                    }
                    {(data.icon_direction == 'C') &&
                      <Grid
                        direction="column"
                        justify="center"
                        style={{ textAlign: 'center' }}
                        container
                        spacing={2}>
                        <Grid key={`icon-${data.id}`} item xs={12}>
                          <Icon name={data.icon} size={44} />
                        </Grid>
                        <Grid key={`text-${data.id}`} item xs={12}>
                          <Typography variant="h6" gutterBottom component="h2">{data.title}</Typography>
                          <Typography variant="body1" component="p">{data.text}</Typography>
                        </Grid>
                      </Grid>
                    }
                  </CardContent>
                </Card>
              </Grid>
            )
          }
          )}
        </Grid>
    </React.Fragment>
  )
}

FeatureSection.defaultProps = {
  type: `features`,
  column: 3
}

FeatureSection.propTypes = {
  type: PropTypes.string,
  column: PropTypes.number
}

export default FeatureSection
