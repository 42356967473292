import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  menulist: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuitem: {
    fontSize: 18,
    color: '#fff',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const data = {
  footer_menu_1: {
    id: 1,
    menu: 'Ongoing Projects',
    menu_items: [
      {
        id: 1,
        text: 'Lotus Heights - Dariyapur',
        url: '/',
        type: 'link',
      },
      {
        id: 2,
        text: 'Lotus Heights - Isanpur',
        url: '/about',
        type: 'link',
      },
    ],
  },
}

function FooterLinks({ type }) {
  const classes = useStyles()
  const FooterMenuData = data[`${type}`]
  return (
    <>
      {FooterMenuData.menu && (
        <Typography variant="h6">{FooterMenuData.menu}</Typography>
      )}
      <List disablePadding className={classes.menulist}>
        {FooterMenuData.menu_items.map(link => {
          return (
            <ListItem disableGutters key={`footer-menu${link.id}`}>
              {link.type == 'link' && (
                <Link className={classes.menuitem} to={link.url}>
                  {link.text}
                </Link>
              )}
              {link.type == 'button' && (
                <Link className={classes.menuitem} to={link.url}>
                  <Button variant="contained" color="primary">
                    {link.text}
                  </Button>
                </Link>
              )}
              {link.type == 'url' && (
                <a className={classes.menuitem} href={link.url} target="_blank">
                  {link.text}
                </a>
              )}
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

FooterLinks.defaultProps = {
  type: `footer_menu_1`,
}

FooterLinks.propTypes = {
  type: PropTypes.string,
}

export default FooterLinks
