
import React, { Component } from 'react';
import Fab from '@material-ui/core/Fab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Typography } from '@material-ui/core';
import { isBrowser } from '../util/commonfunctions'; 

class Chat extends Component {
    componentWillMount() {
        if (isBrowser()) {
        window.addEventListener('scroll', this.handleScroll);
        }
    }
    componentWillUnmount() {
        if (isBrowser()) {
        window.removeEventListener('scroll', this.handleScroll);
        }
    }
    handleScroll = () => {
        if (isBrowser()) {
        if (document.querySelector(".chat-button")) {
            if (document.documentElement.scrollTop < 500) {
                document.querySelector(".chat-button").style = "display: block";
            } else {
                document.querySelector(".chat-button").style = "display: none";
            }
        }
    }
    }

    render() {
        const { url } = this.props;
        return (
            <div className='chat-button' style={{ display: 'none' }}>
                <Fab style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 101 }} color="primary" variant="extended" size="medium" target="_blank" component='a' href={url}>
                    <WhatsAppIcon style={{ marginRight: 8 }} />
                    <Typography variant="subtitle1">Chat</Typography>
                </Fab>
            </div>
        )
    }
}

export default Chat;