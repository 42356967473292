import { makeStyles } from '@material-ui/core/styles'
import gsap from 'gsap'
import React, { useRef, useLayoutEffect } from 'react'

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 700,
    overflow: 'visible',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  
}))

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const CustomButton4 = props => {
  const { children, size, btncolor, ...rest } = props
  const classes = useStyles()
  const svg_ref = useRef()
  const btn_ref = useRef()


  const text_ref = useRef()
  const polygon_ref = useRef()
  const line_ref = useRef()

  const btnwidth = (size === 'small') ? 180 : (size === 'medium') ? 200 : (size === 'large') ? 220 : 220;
  const btnheight = (size === 'small') ? 32 : (size === 'medium') ? 44 : (size === 'large') ? 60 : 60;
  const btntextTrans = (size === 'small') ? 22 : (size === 'medium') ? 28 : (size === 'large') ? 38 : 38;
  const btntextTransY = (size === 'small') ? 100 : (size === 'medium') ? 115 : (size === 'large') ? 130 : 130;
  const btnlineTrans = (size === 'small') ? 32 : (size === 'medium') ? 35 : (size === 'large') ? 38 : 38;
  const btncirclex = (size === 'small') ? 7 : (size === 'medium') ? 11 : (size === 'large') ? 14 : 14;
  const btnfont = (size === 'small') ? 16 : (size === 'medium') ? 18 : (size === 'large') ? 20 : 20;

  useLayoutEffect(() => {
    
    let button = btn_ref.current;
    let svg = svg_ref.current;

    let reftext = text_ref.current;
    let refpolygon = polygon_ref.current;
    let refline = line_ref.current;


    var tl = gsap.timeline();
    tl.to(svg.children[0], {
      duration: 0.4,
      attr: { width: btnwidth },
      ease: 'Power4.easeInOut',
    });
    tl.to(reftext, { duration: 0.4, fill: '#fff', ease: 'Linear.easeNone' }, 0);
    tl.to(refpolygon, { duration: 0.4, x: btncirclex, ease: 'Power4.easeInOut' }, 0);
    tl.to(refline, { duration: 0.4, x: btncirclex, ease: 'Power4.easeInOut' }, 0);
    tl.to(refline, { duration: 0.4, attr: { x2: 3 }, ease: 'Power4.easeInOut' }, 0);
    tl.reversed(true);

    button.addEventListener('mouseenter', () => {
      tl.reversed(!tl.reversed());
    });

   button.addEventListener('mouseleave', () => {
        tl.reversed(!tl.reversed());
      });

  }, []);

  return (
    <a ref={btn_ref}  {...rest}>
      <svg
        className={classes.button}
        ref={svg_ref}
        xmlns="http://www.w3.org/2000/svg"
        width={btnwidth}
        height={btnheight}
        viewBox={`0 0 ${btnwidth} ${btnheight}`}
      >
        <rect
          x="0"
          y="0"
          width={btnheight}
          height={btnheight}
          rx={btnheight/2}
          ry={btnheight/2}
          fill={btncolor}
        />
        <rect
          x="0"
          y="0"
          width={btnwidth}
          height={btnheight}
          rx={btnheight/2}
          ry={btnheight/2}
          fill="none"
          stroke={btncolor}
          stroke-width="2"
        />
        <text
          ref={text_ref}
          transform={`translate(${btntextTransY} ${btntextTrans})`}
          text-anchor="middle"
          font-size={btnfont}
          fill={btncolor}
        >
          {children}
        </text>
        <line
          {...(size === 'small') &&
            {transform:'scale(0.5 0.5)'}
          }
          {...(size === 'medium') &&
          {transform:'scale(0.75 0.75)'}
          }

          ref={line_ref}
          x1={btnlineTrans}
          y1="30"
          x2={btnlineTrans}
          y2="30"
          stroke-width="4"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
       
        <polyline
          {...(size === 'small') &&
          {transform:'scale(0.5 0.5)'}
          }
          {...(size === 'medium') &&
          {transform:'scale(0.75 0.75)'}
          }
          id="start"
          ref={polygon_ref}
          points="27 20 38 30 27 40"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke="#ffffff"
          stroke-width="4"
        ></polyline>
       
      </svg>
    </a>
  )
}

export default CustomButton4
