import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Alert from '@material-ui/lab/Alert';
import Reaptcha from 'reaptcha';
import axios from 'axios'

const useStyles = theme => ({
    mainContainer: {
        paddingTop: 50,
        //paddingBottom: 100
    }
});

class Contact extends React.Component {
    state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        error: {},
        verified: false
    }


    onVerify = recaptchaResponse => {
        this.setState({
            verified: true
        });
    };


    componentDidUpdate(prevProps) {
        const { clearForm } = this.props;
        if (!prevProps.clearForm && clearForm) {
            this.setState({
                name: "",
                email: "",
                phone: "",
                message: "",
                error: {},
                verified: false
            });

            this.captcha.reset()

        }
    }

    resetForm() {
        //this.refs.contactForm.reset();
    }

    handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        const statesToUpdate = {}
        statesToUpdate[name] = value
        this.setState(statesToUpdate)
        const error = this.state.error
        error[name] = '';
        this.setState({
            error
        })
        this.props.initializeForm();
    }

    validateEmail = (email) => {
        var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.match(mailformat)) {
            return true;
        }
        return false;
    }

    handleSubmit = e => {
        let { name, email, phone, message } = this.state
        let params = { name, email, phone, message }
        e.preventDefault();
        if (params.email && !this.validateEmail(email)) {
            var error = this.state.error;
            error['email'] = 'Enter valid email address'
            this.setState({
                error
            })
            return true;
        }
        if (params.name && params.email && params.phone && params.message) {
            this.props.handleSubmit(params);
        } else {
            var error = this.state.error;
            if (!params.name) {
                error['name'] = 'Name is required'
            }
            if (!params.email) {
                error['email'] = 'Email is required'
            }
            if (!params.phone) {
                error['phone'] = 'Phone is required'
            }
            if (!params.message) {
                error['message'] = 'Message is required'
            }
            this.setState({
                error
            })
        };



    }

    render() {
        const { classes, title, subtitle, alertData, alert, reCaptchaKey, loading } = this.props;
        return (
            <Paper style={{ padding: 32, height: '100%' }} elevation={0}>
                <Typography variant="h4">{title}</Typography>
                <Typography variant="body1" gutterBottom>{subtitle}</Typography>
                {alert &&
                    <Alert severity={alertData.type}>
                        {alertData.message}
                    </Alert>
                }
                <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                    <TextField
                        id="standard-name"
                        label="Your Name"
                        variant="filled"
                        value={this.state.name}
                        onChange={this.handleChange}
                        {...(this.state.error.name) &&
                        {
                            error: true,
                            helperText: this.state.error.name
                        }
                        }
                        name="name"
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="standard-email"
                        label="Your Email"
                        variant="filled"
                        value={this.state.email}
                        onChange={this.handleChange}
                        name="email"
                        type="email"
                        {...(this.state.error.email) &&
                        {
                            error: true,
                            helperText: this.state.error.email
                        }
                        }
                        required
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="standard-phone"
                        label="Your Phone"
                        variant="filled"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        name="phone"
                        {...(this.state.error.phone) &&
                        {
                            error: true,
                            helperText: this.state.error.phone
                        }
                        }
                        margin="normal"
                        required
                        fullWidth
                    />
                    <TextField
                        id="standard-message"
                        label="Message"
                        variant="filled"
                        multiline
                        required
                        rowsMax={4}
                        value={this.state.message}
                        onChange={this.handleChange}
                        name="message"
                        {...(this.state.error.message) &&
                        {
                            error: true,
                            helperText: this.state.error.message
                        }
                        }
                        margin="normal"
                        fullWidth
                    />
                    {(reCaptchaKey) &&
                        <div style={{ marginTop: 16 }}>
                            <Reaptcha
                                ref={e => (this.captcha = e)}
                                sitekey={reCaptchaKey}
                                onVerify={this.onVerify}
                            />
                        </div>
                    }
                    <Button disabled={loading || (reCaptchaKey && !this.state.verified)} style={{ marginTop: 16 }} variant="contained" size="large" color="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </Paper>
        )
    }
}

export default withStyles(useStyles)(Contact)