import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from "react"
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import gsap from "gsap"

const useStyles = makeStyles(() => ({
    button: {
        position: 'relative',
        outline: 'none',
        border: 'none',
        borderRadius: 99,
        padding: '1rem 2rem',
        backgroundColor: 'transparent',
        height:44,
        overflow: 'visible',
        color: 'white',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
        buttonspan: {
            position: 'relative',
            zIndex: 2
          },
          svg: {
            position: 'absolute',
            top: '50%',
            right: 0,
            left: '15%',
            width: '75%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            overflow: 'visible'
          }
}));

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const CustomButton3 = props => {
  const { children, ...rest } = props;
  const classes = useStyles();
  const btn= useRef()
  const wibble_ref= useRef()
  const svg_ref= useRef()
  const theme = useTheme();

  let isAnimating = false;
  useLayoutEffect(() => {
        
let svg = svg_ref.current; //document.querySelector("svg");
let wibble = wibble_ref.current; //document.querySelector("#wibble");
var width = 100;
var pointz = 30;
var spacing = width / pointz;

let pointzArray = [];

for (var i = 0; i < pointz; i++) {
  var position = i / (pointz - 1);

  var point = wibble.points.appendItem(svg.createSVGPoint());

  point.x = i * spacing;
  point.y = 25;

  pointzArray.push(point);
}

let button = btn.current;



button.addEventListener("mouseenter", () => {
  if (isAnimating === true) {
    return;
  }

  isAnimating = true;
  pointzArray.forEach((point, index) => {
    var mapper = gsap.utils.mapRange(0, pointz, 0, 0.4);
    if (index === 0) {
      gsap
        .to(point, {
          keyframes: [
            { y: "+=6", ease: "Sine.easeInOut" },
            { y: "-=12", ease: "Sine.easeInOut" },
            { y: "+=6", ease: "Sine.easeInOut" }
          ],
          yoyo: true,
          duration: 0.6,
          onComplete: () => {
            isAnimating = false;
          }
        })
        .progress(mapper(index));
    } else {
      gsap
        .to(point, {
          keyframes: [
            { y: "+=6", ease: "Sine.easeInOut" },
            { y: "-=12", ease: "Sine.easeInOut" },
            { y: "+=6", ease: "Sine.easeInOut" }
          ],
          yoyo: true,
          duration: 0.6
        })
        .progress(mapper(index));
    }
  });
});
},[])

  return (
    <a ref={btn} className={classes.button} {...rest}>
    <span className={classes.buttonspan}>{children}</span>
    <svg ref={svg_ref} className={classes.svg} viewBox="0 0 100 50" preserveAspectRatio="none">
        <polyline ref={wibble_ref} stroke={theme.palette.secondary.main} id="wibble" fill="none" strokeWidth="45" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    </a>
  );
};


export default CustomButton3;
