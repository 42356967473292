import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import 'react-image-lightbox/style.css'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const useStyles = makeStyles(theme => ({
  squareimg:{
    height: 350,
    width: 350
  }
}));

const PhotoGallery = ({
  images = null,
  imgClass = '',
  lightboxOptions = {},
  width
}) => {
  let thumbsArray, fullArray, thumbAltArray

  // New style with all images in one prop
  thumbsArray = images
    .filter((thumb) => thumb !== undefined)
    .map(({ thumb }) => thumb)
  thumbAltArray = images.map(({ thumbAlt }) => thumbAlt)
  fullArray = images
    .filter((image) => image.full !== undefined)
    .map(({ full }) => full.src)

  const [index, setIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const prevIndex = index - (1 % fullArray.length)
  const nextIndex = (index + fullArray.length + 1) % fullArray.length
  const classes = useStyles();
  const customStyles = {
        overlay: {zIndex: 100001}
    }

  const getGridListCols = () => {
    if (isWidthUp('xl', width)) {
      return 4;
    }

    if (isWidthUp('lg', width)) {
      return 4;
    }

    if (isWidthUp('md', width)) {
      return 3;
    }

    return 2;
  }
  
  return (
    <React.Fragment>
      <ImageList style={{
        width: '100%',
        //maxHeight: 320,
        //flexWrap: 'nowrap'
        }} cols={getGridListCols()} rowHeight={'auto'}>
      {thumbsArray.map((thumbnail, thumbIndex) => {
          return (
          <ImageListItem 
            key={thumbIndex}
            onClick={() => {
                setIsOpen(true)
                setIndex(thumbIndex)
            }}>
            <Img
                fluid={thumbnail}
                className={imgClass}
                alt={
                thumbAltArray
                    ? thumbAltArray[thumbIndex]
                    ? thumbAltArray[thumbIndex]
                    : ''
                    : ''
                }
            />
          </ImageListItem>
          )
        }
        )}
      </ImageList>

      {isOpen && (
        <Lightbox
          mainSrc={fullArray[index]}
          nextSrc={fullArray[nextIndex]}
          prevSrc={fullArray[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          imageTitle={images[index].title}
          reactModalStyle={customStyles}
          imageCaption={images[index].caption}
          {...lightboxOptions}
        />
      )}
    </React.Fragment>
  )
}

export default withWidth()(PhotoGallery);

PhotoGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      full: PropTypes.object,
      thumb: PropTypes.object,
      thumbAlt: PropTypes.string,
      title: PropTypes.node,
      caption: PropTypes.node,
    })
  ),
  colWidth: PropTypes.number,
  mdColWidth: PropTypes.number,
  gutter: PropTypes.string,
  imgClass: PropTypes.string,
  lightboxOptions: PropTypes.object,
}
