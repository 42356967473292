import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Slider from "react-slick";
import './index.css'

const useStyles = makeStyles((theme) => ({

}));

export default ({ children, backgroundImage, settings }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div style={{ backgroundImage: (backgroundImage) ? `url('${backgroundImage}')` : null }}>
        <Slider {...settings}>
          {children}
        </Slider>
      </div>
    </React.Fragment>
  )
}

