import React from 'react'
import PropTypes from 'prop-types'
import { GlobalSEO } from '@sites/shared-ui'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalSEO
            site={data.site}
            title={title}
            description={description}
            lang={lang}
            meta={meta}
          />
        </>
      )}
    />
  )
}

export default SEO
