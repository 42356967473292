import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Link } from "gatsby"
import { Icon } from '@sites/shared-ui';
import PropTypes from "prop-types"
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import pricingdata from '../constants/pricing-data';
import comparedata from '../constants/compare-plans';
import Section from './Section';

const useStyles = makeStyles((theme) => ({
  common_plan: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  nopad: {
    paddingBottom: 0,
    paddingTop: 0
  }
}));

function PricingSection({ comparePlans, pricingPlans }) {
  const classes = useStyles();
  const Pricingdata = (pricingPlans) ? pricingPlans : pricingdata;
  const ComparePlans = (comparePlans) ? comparePlans : comparedata;
  return (
    <React.Fragment>

      {(pricingPlans) &&
        <Section narrowPadding>
          <Grid
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
            container>
            {Pricingdata.map((data, index) => {
              return (
                <Grid key={`price-${index}`} item xs={12} md={12 / Pricingdata.length}>
                  <Paper style={{ padding: 32, height: '100%' }}>
                    <Typography variant="h5" gutterBottom>{data.plan_name}</Typography>
                    <Typography variant="h4" gutterBottom>{data.plan_rate}</Typography>
                    <List>
                      {data.items.map((row, index2) => {
                        return (
                          <ListItem key={`p-${index2}`} className={classes.nopad} disableGutters>
                            {(data.icon) &&
                              <ListItemIcon>
                                <Icon name={data.icon} size={12} />
                              </ListItemIcon>
                            }
                            <ListItemText
                              primary={row}
                            />
                          </ListItem>
                        )
                      }
                      )}
                    </List>
                    {(data.plan_url) &&
                      <Link to={data.plan_url}>
                        <Button fullWidth variant="contained" color={(data.buttonColor) ? data.buttonColor : "primary"}>{data.plan_button}</Button>
                      </Link>
                    }
                  </Paper>
                </Grid>
              )
            }
            )}
          </Grid>
        </Section>
      }
      {(comparePlans) &&
        <Section narrowPadding>
          <Paper square elevation={0}>
            <Typography variant="h6" gutterBottom style={{ padding: 16 }}>Compare features</Typography>
            <TableContainer>
              <Table className={classes.table} size="small" padding="default">
                <TableBody>
                  {ComparePlans.map((row, index3) => {
                    return (
                      <TableRow key={`c-${index3}`}>
                        <TableCell style={{ minWidth: 300 }}><Typography variant="body1">{row.desc}</Typography></TableCell>
                        <TableCell align="right" style={{ width: 200 }}><Typography variant="body1">{row.plan1}</Typography></TableCell>
                        <TableCell align="right" style={{ width: 200 }}><Typography variant="body1">{row.plan2}</Typography></TableCell>
                        <TableCell align="right" style={{ width: 200 }}><Typography variant="body1">{row.plan3}</Typography></TableCell>
                        <TableCell align="right" style={{ width: 200 }}><Typography variant="body1">{row.plan4}</Typography></TableCell>
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Typography variant="body2" style={{ marginTop: 8 }}>*Price exclusive GST</Typography>
        </Section>
      }
    </React.Fragment>
  )
}

export default PricingSection
