import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    outline: 0,
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(12, 2),
    },
  },
  fullWidth: {
    maxWidth: '100%',
  },
  disablePadding: {
    padding: 0,
  },
  section: {
    width: '100%',
  },
  sectionStyled: {
    width: '100%',
    position: 'relative',
    "&:before": {
      content: '""',
      backgroundColor: '#000',
      position: 'absolute',
      height: '100%',
      right: 0,
      zIndex: 1,
      top: 0,
      width: 500,
    },
    [theme.breakpoints.down('md')]: {
      "&:before": {
        display: 'none'
      }
    }
  },
  narrowPadding: {
    padding: theme.spacing(4, 2),
  },
  hugePadding: {
    padding: theme.spacing(16, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2),
    },
  },
  narrow: {
    maxWidth: 960,
  },
}));

/**
 * Component to display the sections
 *
 * @param {Object} props
 */
const Section = props => {
  const {
    children,
    fullWidth,
    narrow,
    styled,
    disablePadding,
    hugePadding,
    narrowPadding,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <section
      className={clsx(
        'sec',
        styled ? classes.sectionStyled : classes.section,
        classes.root,
        fullWidth ? classes.fullWidth : {},
        narrow ? classes.narrow : {},
        disablePadding ? classes.disablePadding : {},
        narrowPadding ? classes.narrowPadding : {},
        hugePadding ? classes.hugePadding : {},
        className,
      )}
      {...rest}>
      {children}
    </section>
  );
};

Section.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should show narrow sections
   */
  narrow: PropTypes.bool,
  /**
   * Should the section be full width
   */
  fullWidth: PropTypes.bool,
  /**
   * Should the section render with no padding
   */
  disablePadding: PropTypes.bool,
  /**
   * Should the section render with narrow padding
   */
  narrowPadding: PropTypes.bool,
  /**
   * Should the section render with Huge padding
   */
  hugePadding: PropTypes.bool,
  styled: PropTypes.bool,
};

export default Section;