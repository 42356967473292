import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import { config } from '../util/config';
import { validateEmail, isblank } from '../util/commonfunctions';

var verifyStep = 'email';
var loading = false;

const useStyles = theme => ({
    mainContainer: {
        backgroundImage: 'url(https://cdn.arihantsolutions.com/gms/hero-woman.jpg)',
        height: 'calc(100vh - 64px)',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.3)',
        display: 'flex',
        alignItems: 'center'
    }
});

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            step: 1,
            seconds: 60,
            companyName: "",
            email: '',
            mobile: '',
            otp: '',
            companyurl: '',
            userName: '',
            password: '',
            accountPackage: '',
            alert: false,
            alertData: {},
            userData: {}
        };
    }

    showAlert(type, message) {
        loading = false;
        this.setState({
            alert: true,
            alertData: { type, message }
        });
        setTimeout(() => {
            this.setState({ alert: false });
        }, 4000)
    }


    handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        const statesToUpdate = {}
        statesToUpdate[name] = value
        const error = this.state.error;
        error[name] = ''
        this.setState({
            error
        })
        this.setState(statesToUpdate)
    }

    changeEmail = () => {
        this.setState({
            step: 1,
            email: '',
            otp: ''
        })
    }

    validate = () => {
        const values = this.state;
        const { moduleType } = this.props;
        const errors = {}
        if (verifyStep === 'email') {
            if (!values['companyName']) {
                errors['companyName'] = 'Enter Company Name'
            }
            if (!values['email']) {
                errors['email'] = 'Enter Email Address'
            }
            else {
                if (!validateEmail(values['email'])) {
                    errors['email'] = 'Enter valid email address'
                }
            }
            if (!values['mobile']) {
                errors['mobile'] = 'Enter Mobile No.'
            }
        }
        else if (verifyStep === 'otp') {
            if (!values['otp']) {
                errors['otp'] = 'Enter OTP'
            }
        }
        else if (verifyStep === 'submit') {
            if (!values['companyurl']) {
                errors['companyurl'] = 'Enter Company URL'
            }
            if (!values['userName']) {
                errors['userName'] = 'Enter User Name'
            }
            if (!values['password']) {
                errors['password'] = 'Enter Password'
            }

            if (moduleType === 'accounting') {
                if (!values['accountPackage']) {
                    errors['accountPackage'] = 'Enter Account Package'
                }
            }
        }
        return errors;
    }


    startTimer = () => {
        this.myInterval = setInterval(() => {
            const { seconds } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                clearInterval(this.myInterval)
            }
        }, 1000)
    }


    handleBack = () => {
        clearInterval(this.myInterval)
        this.setState({
            step: 1,
            otp: ''
        })
    }


    handleSave = (type, event) => {

        event.preventDefault();

        verifyStep = type

        if (type === 'resend') {
            clearInterval(this.myInterval)
        }

        const errors = this.validate();
        if (!isblank(errors) && Object.keys(errors).length > 0) {
            this.setState({
                error: errors
            })
        }
        else {
            loading = true;
            const values = this.state;
            var formdata = {};
            if (verifyStep === 'email') {
                formdata = {
                    companyName: values.companyName,
                    email: values.email,
                    mobile: values.mobile,
                    step: 1,
                };
            }
            else if (verifyStep === 'otp') {
                formdata = {
                    insert_code: values.insert_code,
                    step: 2,
                    otp: values.otp
                }
            }
            else if (verifyStep === 'resend') {
                formdata = {
                    insert_code: values.insert_code,
                    step: 4,
                }
            }
            else {
                formdata = {
                    companyName: values.companyName,
                    email: values.email,
                    mobile: values.mobile,
                    insert_code: values.insert_code,
                    otp: values.otp,
                    userName: values.userName,
                    password: values.password,
                    companyurl: values.companyurl,
                    accountPackage: values.accountPackage,
                }
                formdata.step = 3;
            }
            formdata.moduleType = this.props.moduleType

            const formdata2 = Array.isArray(formdata) ? formdata.map(JSON.stringify) : JSON.stringify(formdata)
            let data = new FormData();
            data.append('type', 'signup');
            data.append('action', 'insert');
            data.append('form_data', formdata2);
            axios({
                method: 'post',
                url: `${config.BASE_URL}/master/signup`,
                data: data
            }).then((response) => {
                loading = false;
                if (response.data.meta.code === 402) {
                    this.showAlert('warning', 'Session has been expired');
                }
                else if (response.data.meta.code === 401) {
                    this.showAlert('warning', response.data.meta.message);
                }
                else {
                    if (formdata.step === 1) {
                        this.setState({
                            insert_code: response.data.result.insert_code,
                            step: 2,
                            seconds: 60
                        })
                        this.startTimer();
                    }
                    else if (formdata.step === 2) {
                        clearInterval(this.myInterval)
                        this.setState({
                            step: 3
                        })
                    }
                    else if (formdata.step === 3) {
                        this.showAlert('success', 'Account Created successfully');
                    }
                    else if (formdata.step === 4) {
                        this.startTimer();
                        this.setState({
                            seconds: 60
                        })
                    }
                }
            }).catch((error) => {
                loading = false;
                this.showAlert('warning', 'Please contact administrator');
            })
        }
    };
    render() {
        const { classes } = this.props;
        const { seconds } = this.state;
        return (
            <>
                <Fade in={this.state.step === 1} mountOnEnter unmountOnExit>
                    <Paper style={{ padding: 32, marginBottom: 32 }} elevation={0}>
                        <form noValidate autoComplete="off" onSubmit={(event) => this.handleSave('email', event)}>
                            <Typography variant="h5">Get Started with a Free Account!</Typography>
                            {this.state.alert &&
                                <Alert severity={this.state.alertData.type}>
                                    {this.state.alertData.message}
                                </Alert>
                            }

                            <InputLabel error={!isblank(this.state.error.companyName)} style={{ marginBottom: 4, marginTop: 8 }}>Company Name</InputLabel>
                            <TextField
                                id="standard-name"
                                variant="outlined"
                                value={this.state.companyName}
                                placeholder="Enter company name"
                                onChange={this.handleChange}
                                name="companyName"
                                required
                                autoFocus={true}
                                fullWidth
                                {...(this.state.error.companyName) &&
                                {
                                    error: true,
                                    helperText: this.state.error.companyName
                                }
                                }
                            />


                            <InputLabel error={!isblank(this.state.error.email)} style={{ marginBottom: 4, marginTop: 8 }}> Email Address</InputLabel>
                            <TextField
                                id="standard-email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                name="email"
                                type="email"
                                required
                                variant="outlined"
                                fullWidth
                                {...(this.state.error.email) &&
                                {
                                    error: true,
                                    helperText: this.state.error.email
                                }
                                }
                            />
                            <InputLabel error={!isblank(this.state.error.mobile)} style={{ marginBottom: 4, marginTop: 8 }}>Mobile Number</InputLabel>

                            <TextField
                                id="standard-phone"
                                value={this.state.mobile}
                                onChange={this.handleChange}
                                name="mobile"
                                variant="outlined"
                                required
                                fullWidth
                                {...(this.state.error.mobile) &&
                                {
                                    error: true,
                                    helperText: this.state.error.mobile
                                }
                                }
                            />

                            <div id="recaptcha-container" />
                            <p>Verify your email to create your Account</p>
                            <div style={{ position: 'relative' }}>
                                <Button fullWidth disabled={loading} variant="contained" size="large" color="primary" type="submit">
                                    Send OTP
                                </Button>
                                {loading && <CircularProgress disableShrink size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                            </div>
                        </form>
                    </Paper>
                </Fade>
                <Fade in={this.state.step === 2} mountOnEnter unmountOnExit>
                    <Paper style={{ padding: 32, marginBottom: 32 }} elevation={0}>
                        <form noValidate autoComplete="off" onSubmit={(event) => this.handleSave('otp', event)}>
                            <Typography variant="h5" gutterBottom>Verify mobile</Typography>
                            {this.state.alert &&
                                <Alert severity={this.state.alertData.type}>
                                    {this.state.alertData.message}
                                </Alert>
                            }
                            <Typography variant="body1">Enter the OTP sent to your email</Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2">{this.state.email}</Typography>
                                <Button size="small" onClick={this.changeEmail}>Change</Button>
                            </div>

                            <InputLabel error={!isblank(this.state.error.otp)} style={{ marginBottom: 4, marginTop: 8 }}>OTP</InputLabel>

                            <TextField
                                id="standard-otp"
                                variant="outlined"
                                value={this.state.otp}
                                onChange={this.handleChange}
                                name="otp"
                                required
                                fullWidth
                                {...(this.state.error.otp) &&
                                {
                                    error: true,
                                    helperText: this.state.error.otp
                                }
                                }
                            />
                            <div style={{ marginBottom: 8 }}><Button size="small" disabled={seconds !== 0} onClick={(event) => this.handleSave('resend', event)}>RESEND OTP</Button></div>
                            <Typography>Time Remaining:{seconds}</Typography>
                            <div style={{ position: 'relative' }}>
                                <Button style={{ marginBottom: 8 }} fullWidth variant="contained" size="large" color="primary" type="submit">
                                    Verify
                                </Button>
                                {loading && <CircularProgress disableShrink size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                            </div>
                            <Button fullWidth size="large" color="primary" type="submit" onClick={this.handleBack}>
                                Back
                            </Button>
                        </form>
                    </Paper>
                </Fade>
                <Fade in={this.state.step === 3} mountOnEnter unmountOnExit>
                    <Paper style={{ padding: 32 }} elevation={0}>
                        <form noValidate autoComplete="off" onSubmit={(event) => this.handleSave('submit', event)}>
                            <Typography variant="h5">Set up your store</Typography>
                            {this.state.alert &&
                                <Alert severity={this.state.alertData.type}>
                                    {this.state.alertData.message}
                                </Alert>
                            }

                            <InputLabel error={!isblank(this.state.error.companyurl)} style={{ marginBottom: 4, marginTop: 8 }}>Company URL</InputLabel>

                            <TextField
                                id="standard-storename"
                                variant="outlined"
                                value={this.state.companyurl}
                                onChange={this.handleChange}
                                name="companyurl"
                                required
                                fullWidth
                                {...(this.state.error.companyurl) &&
                                {
                                    error: true,
                                    helperText: this.state.error.companyurl
                                }
                                }
                            />

                            <InputLabel error={!isblank(this.state.error.userName)} style={{ marginBottom: 4, marginTop: 8 }}>Username</InputLabel>
                            <TextField
                                id="standard-username"
                                variant="outlined"
                                value={this.state.userName}
                                onChange={this.handleChange}
                                name="userName"
                                required
                                fullWidth
                                {...(this.state.error.userName) &&
                                {
                                    error: true,
                                    helperText: this.state.error.userName
                                }
                                }
                            />

                            <InputLabel error={!isblank(this.state.error.password)} style={{ marginBottom: 4, marginTop: 8 }}>Password</InputLabel>
                            <TextField
                                id="standard-password"
                                label="Password"
                                variant="outlined"
                                value={this.state.password}
                                onChange={this.handleChange}
                                name="password"
                                type='password'
                                required
                                fullWidth
                                {...(this.state.error.password) &&
                                {
                                    error: true,
                                    helperText: this.state.error.password
                                }
                                }
                            />

                            <div style={{ position: 'relative' }}>
                                <Button fullWidth disabled={loading} style={{ marginTop: 16 }} variant="contained" size="large" color="primary" type="submit">
                                    Next: Set up your Store
                                </Button>
                                {loading && <CircularProgress disableShrink size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                            </div>
                        </form>
                    </Paper>
                </Fade>
            </>)
    }
}

export default withStyles(useStyles)(Contact)