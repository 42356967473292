import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import servicedata from '../constants/service-data';
import Slide from 'react-reveal/Slide';

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    textAlign: 'center',
    height: '100%'
  }
}));

export default ({ data, md, xs }) => {
  const Data = (data) ? data : servicedata
  const MD = (md) ? md : 3
  const XS = (xs) ? xs : 12
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container alignItems="stretch" spacing={2}>
        {Data.map((service, index) => {
          return (
            <Grid key={index} item xs={XS} md={MD} style={{ display: 'flex' }}>
              {(service.buttonLink !== '' && service.buttonLink !== undefined) ?
                <Link to={service.buttonLink} target="_blank">
                  <Card key={service.id} className={classes.serviceCard}>
                    <CardActionArea>
                      <CardContent>
                        <div>
                          {(service.image_direction == 'C') &&
                            <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                          }
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
                :
                <Card key={service.id} className={classes.serviceCard}>
                  <CardActionArea>
                  <CardContent>
                    <div>
                      {(service.image_direction == 'C') &&
                        <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                      }
                    </div>
                  </CardContent>
                  </CardActionArea>
                </Card>
              }
            </Grid>
          )
        }
        )}
      </Grid>
    </React.Fragment>
  )
}

