import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Section from './Section';

const useStyles = makeStyles((theme) => ({
  nopad: {
    padding: 0,
    alignItems: 'baseline'
  },
  defaultAlign: {
    //minHeight: 450,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse'
  },
  right: {
    //minHeight: 450,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse'
  },
  left: {
    //minHeight: 450,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  center: {
    //minHeight: 450,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  hero: {
    backgroundImage: 'url(https://cdn.arihantsolutions.com/fb/assets/mobile-apps.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  }
}));

export default ({ children, align }) => {
  const classes = useStyles();
  return (
    <div className={classes.hero}>
      <Section>
        <div className={(align) ? classes[align] : classes.defaultAlign}>
          {children}
        </div>
      </Section>
    </div>
  )
}