import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close';
import PageLinks from '../constants/links'
import CatLinks from '../constants/cat-links'
import Container from '@material-ui/core/Container'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const drawerWidth = '100%'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  fill: {
    flexGrow: 1,
  },
  appBar: {
    background: '#000',
    zIndex: theme.zIndex.drawer + 1
  },
  appBarTrans: {
    background: 'transparent',
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  hide: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))

const Header = props => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      allProjectsJson {
        edges {
          node {
            id
            title
          }
        }
      }
      headerLogo: file(relativePath: { eq: "lotus_logo.png" }) {
        childImageSharp {
          fixed(fit: CONTAIN, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [open, setOpen] = React.useState(false)
  const projects_links = data.allProjectsJson.edges;

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  return (
    <>
      <CssBaseline />
        <AppBar
          position={props.position ? props.position : 'sticky'}
          elevation={0}
          id="header"
          className={
            props.position === 'absolute' ? classes.appBarTrans : classes.appBar
          }
        >
        <Container disableGutters maxWidth="lg">
          <Toolbar>
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Link to="/">
              <Img
                placeholderStyle={{ visibility: 'hidden' }}
                fixed={data.headerLogo.childImageSharp.fixed}
                alt="thelotusgroup.co"
              />
            </Link>
            <div className={classes.fill}></div>
            <div className={classes.hide}>
              <PageLinks selectedpage={props.selectedpage} mode="desktop" />
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        className={classes.drawer}
        //variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>

        <PageLinks mode="mobile" />
        <CatLinks menu={projects_links} mode="mobile" />
      </Drawer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menu: PropTypes.array,
}

export default Header
