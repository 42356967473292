import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Link } from "gatsby"
import { CenterFocusStrong } from "@material-ui/icons";
import PropTypes from "prop-types"
import Slider from '../../constants/hero-data';
import Section from '../Section';


const useStyles = makeStyles((theme) => ({
    heroCard: {
        backgroundColor: 'transparent',
        outline: 'none',
        color: '#fff'
    },
    heroCard2: {
        backgroundColor: 'transparent',
        outline: 'none',
        //color: '#fff'
    },

    heroOutlineButton: {
        color: '#fff',
        borderColor: '#fff',
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
            width: '100%'
        }
    },

    heroOutlineButton2: {
        //color: '#fff', 
        //borderColor: '#fff'
    },

    heroButton: {
        marginRight: 0,
        marginBottom: 16,
        [theme.breakpoints.up("sm")]: {
            marginRight: 16,
            marginBottom: 0,
        },
        [theme.breakpoints.down("xs")]: {
            marginRight: 0,
            marginBottom: 16,
            width: '100%'
        }
    },

    hero: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'scroll',
        display: 'flex',
        alignItems: 'center',
    }
}
));

function HeroTemplate1({ sliderData, type }) {
    const classes = useStyles();
    const SliderData = (sliderData) ? sliderData : Slider[`${type}`]
    return (
        SliderData.slides.map(data => {
            return (
                <Card elevation={0} key={data.id} className={(SliderData.backgroundImage) ? classes.heroCard : classes.heroCard2} square>
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid style={{ textAlign: 'center' }} item xs={12} md={(data.image) ? 7 : 12}>
                                <Typography variant="h3" gutterBottom>{data.heading}</Typography>
                                {(data.subheading !== '') &&
                                    <Typography variant="h5" gutterBottom>{data.subheading}</Typography>
                                }
                                {(data.subheading2 !== '') &&
                                    <Typography variant="h6" gutterBottom>{data.subheading2}</Typography>
                                }
                                {(data.button1 !== '' || data.button2 !== '') &&
                                    <div style={{ marginTop: 32 }}>
                                        {(data.button1 !== '') &&
                                            <Link to={data.buttonLink}>
                                                <Button variant="contained" color="secondary" size="large" className={classes.heroButton}>{data.button1}</Button>
                                            </Link>
                                        }
                                        {(data.button2 !== '') &&
                                            <Link to={data.buttonLink2}>
                                                <Button variant="outlined" className={(SliderData.backgroundImage) ? classes.heroOutlineButton : classes.heroOutlineButton2} size="large">{data.button2}</Button>
                                            </Link>
                                        }
                                    </div>
                                }

                            </Grid>
                            {(data.image) &&
                                <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                                    <img style={{ width: '100%', maxWidth: 450 }} src={data.image} />
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            )
        }
        )
    )
}

export default HeroTemplate1