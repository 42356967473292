import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { CustomButton, CustomButton2 } from '@sites/shared-ui';
import { isblank } from '@sites/shared-ui/util/commonfunctions'
import { Link } from "gatsby"
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: props => props.align
  }
}));

const Title = props => {
  const {
    title,
    titlevariant,
    subtitle,
    subtitlevariant,
    button,
    buttontype,
    buttonurl,
    align,
    subcomponent
  } = props;

  const classes = useStyles(props);
  return <React.Fragment>
    <div
      className={classes.root} {...props}>
      <Typography variant={(titlevariant) ? titlevariant : "h3"} gutterBottom>{title}</Typography>
      {(subtitle !== '' && subtitle !== undefined) &&
        <Typography variant={(subtitlevariant) ? subtitlevariant : "h6"} gutterBottom>{subtitle}</Typography>
      }
      {(buttonurl !== '' && buttonurl !== undefined) &&
        <Link to={buttonurl}>
          {(buttontype && buttontype === 'custom') &&
            <CustomButton size="large" style={{ marginTop: 24 }}>{button}</CustomButton>
          }
          {(buttontype && buttontype === 'custom2') &&
            <CustomButton2 size="large" style={{ marginTop: 24 }}>{button}</CustomButton2>
          }
          {(isblank(buttontype)) &&
            <Button variant="contained" color="secondary" size="large" style={{ marginTop: 24 }}>{button}</Button>
          }
        </Link>
      }
     
      {subcomponent}
     
    </div>
  </React.Fragment>
}

export default Title