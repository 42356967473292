import { responsiveFontSizes } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import palette from './palette'
import typography from './typography'

const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    layout: {
      contentWidth: 1140,
    },
    overrides: {
      MuiButton: {},
    },
  })
)

export default theme
