import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import FAQS from "../constants/faqs"
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
  },
}));

export default ({ faqs }) => {
  const classes = useStyles();
  var Faqs = (faqs) ? faqs : FAQS;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>Frequently Asked Questions</Typography>
      <Typography variant="body1" gutterBottom>Have a look at some of the most frequently asked questions by our customers and their answers. Feel free to contact us in case of any queries.</Typography>

      { Faqs.map(faq => {
        return (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={faq.id}
              >
                <Typography variant="body1" className={classes.heading}>{faq.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  {faq.text}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        )
      }
      )}
    </React.Fragment>
  )
}

