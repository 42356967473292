import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Section from '../Section';
import Grid from '@material-ui/core/Grid'
const useStyles = makeStyles({
    bgColor: {
        backgroundColor: props => props.backgroundColor,
    },
    skewed: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: '#2c3e50',
        zIndex: 0,
        transform: 'skewY(50deg)',
        transformOrigin: 'top right'
    }
});

/**
 * Component to display the hero with background
 */
const HeroGridStyled = props => {
    const {
        backgroundColor,
        leftmd,
        leftContent,
        rightContent,
        rightmd,
        ...rest
    } = props;

    const classes = useStyles(props);
    const leftMd = (leftmd) ? leftmd : 6;
    const rightMd = (rightmd) ? rightmd : 6;
    return (
        <div
            className={clsx(
                'background-wrapper',
                backgroundColor ? classes.bgColor : {}
            )} style={{ position: 'relative' }}>
            <Section {...rest}>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={leftMd}>
                        {leftContent}
                        <div className={classes.skewed}></div>
                    </Grid>
                    <Grid item xs={12} md={rightMd}>
                        {rightContent}
                    </Grid>
                </Grid>
            </Section>
        </div>
    );
};

HeroGridStyled.propTypes = {
    backgroundColor: PropTypes.string,
    leftmd: PropTypes.number,
    rightmd: PropTypes.number,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
};

export default HeroGridStyled;