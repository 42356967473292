

import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowUp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { isBrowser } from '../util/commonfunctions'; 

const useStyles = theme => ({
    scrolltop: {
        right: 16,
        bottom: 16,
        position: 'fixed'
    }
});

class TapToTop extends Component {
    componentWillMount() {
        if (isBrowser()) {
            window.addEventListener('scroll', this.handleScroll);
        }
        
    }
    componentWillUnmount() {
        if (isBrowser()) {
            window.removeEventListener('scroll', this.handleScroll);    
        }
        
    }
    handleScroll = () => {
        if (isBrowser()) {
            if (document.querySelector(".tap-to-top")) {
                if (document.documentElement.scrollTop > 300) {
                    document.querySelector(".tap-to-top").style = "display: block";
                } else {
                    document.querySelector(".tap-to-top").style = "display: none";
                }
            }
        }
        
    }

    clickToTop() {
        if (isBrowser()) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        }
        
    }
    render() {
        const { classes } = this.props;
        return (
            <div className='tap-to-top' onClick={this.clickToTop} style={{ display: 'none' }}>
                <Fab size="small" className={ classes.scrolltop } color="primary" aria-label="scroll back to top">
                    <KeyboardArrowUp />
                </Fab>
            </div>
        )
    }
}

export default withStyles(useStyles)(TapToTop);