import React, { useRef, useLayoutEffect }  from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import gsap from "gsap"

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    textAlign: 'center',
    height: '100%',
    width: '100%'
  }
}));

const CardComp = (props) => {

  const classes = useStyles();
  const container_ref= useRef()
  const {service} = props;


  useLayoutEffect(() => {
    let card = container_ref.current;
      card.addEventListener("mouseenter", () => gsap.timeline().to(card, {
        //duration:0.25,
        scale: 1.1
       // ease: 'Bounce.easeOut',
      }));
      card.addEventListener("mouseleave", () => gsap.timeline().to(card, {
       // duration:0.75,
        scale: 1
        //ease: 'Bounce.easeOut',
      }));

  });

  return(
  <Card ref={container_ref} key={service.id} className={classes.serviceCard}>
  <CardContent>
    <div style={{ marginBottom: 16 }}>
      {(service.image_direction == 'C') &&
        <img style={{ maxHeight: (service.image_height) ? service.image_height : 128, maxWidth: (service.image_width) ? service.image_width : 128, boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
      }
    </div>
    <Typography variant="h6" gutterBottom className={classes.serviceTitle}>{service.title}</Typography>
    <Typography variant="subtitle1" style={{ fontWeight: 'normal' }} gutterBottom>{service.text}</Typography>
    {(service.buttonLink !== '' && service.buttonLink !== undefined) &&
      <Link to={service.buttonLink}>
        <Button variant="contained" size="large" color={(service.buttonColor) ? service.buttonColor : "primary"}>{service.button}</Button>
      </Link>
    }
  </CardContent>
  </Card>)  
}

export default ({ data, md, xs }) => {
  const Data = (data) ? data : []
  const MD = (md) ? md : 3
  const XS = (xs) ? xs : 12
 
  return (
    <React.Fragment>
      <Grid container alignItems="stretch" spacing={2}>
        {Data.map((service, index) => {
          return (
            <Grid key={index} item xs={XS} md={MD} style={{ display: 'flex' }}>
              <CardComp service={service} />
            </Grid>
          )
        }
        )}
      </Grid>
    </React.Fragment>
  )
}

