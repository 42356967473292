import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Slider from "react-slick";
import Testimonials from "../../constants/testimonials-data"
import './index.css'

const useStyles = makeStyles((theme) => ({
  testiCard: {
    marginTop: 32,
    backgroundColor: 'transparent',
    outline: 'none'
  }
}));

export default ({ testimonials }) => {
  const TestiMonials = (testimonials) ? testimonials : Testimonials;
  const classes = useStyles();
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <React.Fragment>
      <Slider {...settings}>
        {TestiMonials.map(testimonial => {
          return (
            <Card elevation={0} key={testimonial.id} className={classes.testiCard} square>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'normal' }}>{testimonial.text}</Typography>
                    <Typography variant="h6" color="textSecondary">{testimonial.title}</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={testimonial.image} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        }
        )}
      </Slider>
    </React.Fragment>
  )
}

