import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    vimeoWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        pointerEvents: 'none',
        overflow: 'hidden'
    },
    vimeoIframe: {
        width: '100vw',
        height: '56.25vw', /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        minHeight: '100vh',
        minWidth: '177.77vh', /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
});

/**
 * Component to display the hero with background
 */
const HeroVideo = props => {
    const {
        videoUrl,
        ...rest
    } = props;

    const classes = useStyles(props);

    return (
        <div className={classes.vimeoWrapper}>
            <iframe className={classes.vimeoIframe} src={videoUrl}
                frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
        </div>
    );
};

HeroVideo.propTypes = {
    /**
     * videoUrl
     */
    videoUrl: PropTypes.string
};

export default HeroVideo;