import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import HomeIcon from '@material-ui/icons/Home'
import {
  converttotitle,
  converttourl,
} from '@sites/shared-ui/util/commonfunctions'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  menulist: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  menulistmob: {
    textAlign: 'center'
  }
}))

const data = [
  {
    id: 1,
    text: 'HOME',
    url: '/',
    type: 'link',
  },
  {
    id: 4,
    text: 'CONTACT US',
    url: '/contact',
    type: 'link',
  },
]

function Links({ mode, menu, selectedpage }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  if (mode == 'desktop') {
    return (
      <List disablePadding className={classes.menulist}>
        {data.map(link => {
          return (
            <React.Fragment key={link.id}>
              {link.type == 'link' && (
                <ListItem
                  style={{ width: 'auto' }}
                  button
                  selected={selectedpage == converttourl(link.text)}
                  component={Link}
                  to={link.url}
                  key={`menu-${link.id}`}
                >
                  <Typography variant="subtitle2">{link.text}</Typography>
                </ListItem>
              )}
              {link.type == 'button' && (
                <ListItem
                  button
                  selected={selectedpage == converttourl(link.text)}
                  component={Link}
                  to={link.url}
                  key={`menu-${link.id}`}
                >
                  <Button variant="contained" color="primary">
                    {link.text}
                  </Button>
                </ListItem>
              )}
              {link.type == 'url' && (
                <ListItem
                  button
                  selected={selectedpage == converttourl(link.text)}
                  component="a"
                  key={`menu-${link.id}`}
                  href={link.url}
                  target="_blank"
                >
                  <Typography variant="subtitle2">{link.text}</Typography>
                </ListItem>
              )}
            </React.Fragment>
          )
        })}
      </List>
    )
  } else {
    return (
      <List className={classes.menulistmob}>
        {data.map(link => {
          return (
            <React.Fragment key={link.id}>
              {link.type == 'link' && (
                <ListItem
                  button
                  style={{ textAlign: 'center', justifyContent: 'center'}}
                  component={Link}
                  to={link.url}
                  key={`menu-${link.id}`}
                >
                  <Typography variant="h4">{link.text}</Typography>
                </ListItem>
              )}
              {link.type == 'button' && (
                <ListItem
                  button
                  component={Link}
                  style={{ textAlign: 'center', justifyContent: 'center'}}
                  to={link.url}
                  key={`menu-${link.id}`}
                >
                  <Typography variant="h4">{link.text}</Typography>
                </ListItem>
              )}
              {link.type == 'url' && (
                <ListItem
                  button
                  component="a"
                  style={{ textAlign: 'center', justifyContent: 'center'}}
                  key={`menu-${link.id}`}
                  href={link.url}
                  target="_blank"
                >
                  <Typography variant="h4">{link.text}</Typography>
                </ListItem>
              )}
            </React.Fragment>
          )
        })}
      </List>
    )
  }
}

Links.defaultProps = {
  mode: `desktop`,
}

Links.propTypes = {
  mode: PropTypes.string,
}

export default Links
