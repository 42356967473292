import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import servicedata from '../constants/service-data';

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    marginBottom: 32,
    padding: theme.spacing(2),
  }
}));

export default ({ data }) => {
  const Data = (data) ? data : servicedata;
  const classes = useStyles();
  return (
    <React.Fragment>
      {Data.map(service => {
        return (
          <Card key={service.id} className={classes.serviceCard} square>
            <CardContent>
              <Grid container spacing={2}>
                {(service.image_direction == 'L') &&
                  <Grid item xs={12} md={4}>
                    <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                  </Grid>
                }
                <Grid item xs={12} md={8}>
                  <Typography variant="h4" gutterBottom className={classes.serviceTitle}>{service.title}</Typography>
                  <Typography variant="h6" style={{ fontWeight: 'normal', marginBottom: 16 }} gutterBottom>{service.text}</Typography>
                  <Link to={service.buttonLink}>
                    <Button>{service.button}</Button>
                  </Link>
                </Grid>
                {(service.image_direction == 'R') &&
                  <Grid item xs={12} md={4}>
                    <img style={{ width: '100%', boxShadow: 'none', border: 0, margin: 0 }} src={service.image} />
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>
        )
      }
      )}
    </React.Fragment>
  )
}

